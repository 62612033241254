import gql from 'graphql-tag'

export const exportSupportersCsv = gql`
  mutation exportSupportersCsv {
    exportSupportersCsv(input: {}) {
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
