import React from 'react'
import { clsx } from 'clsx'

import { Checkbox } from '@atoms/form'

type ToggleableFieldProps = {
  label: string
  name: string
  tooltip: string
  isEnabled: boolean
  setEnabled: (value: boolean) => void
  children: React.ReactNode
}
const ToggleableField = ({ label, name, children, tooltip, isEnabled, setEnabled }: ToggleableFieldProps) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-flex-col tw-align-items-center tw-gap-2',
        'tw-w-full tw-p-4 tw-border  tw-rounded-lg',
        isEnabled ? 'tw-bg-primary-50 tw-border-primary-200' : 'tw-bg-white'
      )}
      data-testid="toggleable-field"
    >
      <div className="tw-flex tw-w-full tw-gap-3">
        <div className="tw-flex tw-items-center">
          <Checkbox
            name={name}
            defaultChecked={isEnabled}
            onClick={() => {
              setEnabled(!isEnabled)
            }}
          >
            <div className="tw-pl-2">{label}</div>
          </Checkbox>
        </div>

        <div className="tw-flex-1">{children}</div>
      </div>

      <p className="tw-text-gray-500">{tooltip}</p>
    </div>
  )
}

export default ToggleableField
