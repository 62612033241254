import React from 'react'
import SearchField from '@molecules/SearchField'
import { scopedTranslation } from '@utils/I18n'

import useSupporterFilteringStore from '../filter-supporters/stores/useSupporterFilteringStore'

const tGroupShow = scopedTranslation('group_show')

function SupporterSearchField() {
  const name = useSupporterFilteringStore((state) => state.supporterName)
  const setName = useSupporterFilteringStore((state) => state.updateName)

  return (
    <SearchField
      fieldName="supporter_name"
      placeholder={tGroupShow('search_supporters')}
      displayName={tGroupShow('search_supporters_label')}
      onChange={(event) => {
        setName(event.target.value)
      }}
      defaultValue={name}
      dataCompId="group-detail-page_search-field"
    />
  )
}

export default SupporterSearchField
