import React from 'react'

import { Slot } from '@hooks/useSlots'

import { Link } from '@atoms'
import InfoCard from '@molecules/InfoCard'

import type { Group, GroupCriteriaRule, User } from '@frontend/graphql/types.generated'

import GroupAddress from './GroupAddress'
import GroupCriteria from './GroupCriteria'
import PointPeople from './PointPeople'
import LeaderNames from './LeaderNames'
import type { SharedInertiaProps } from '../../pages/GroupShow'

import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components.group_card')

export type GroupCardProps = Pick<
  Group,
  | 'id'
  | 'name'
  | 'description'
  | 'streetAddress'
  | 'city'
  | 'postcode'
  | 'state'
  | 'notifyAllAdmins'
  | 'publicUrl'
  | 'status'
> & {
  supporters: Pick<Group['supporters'], 'totalCount'>
  pointPeople: {
    nodes: Array<Pick<User, 'email'>>
  }
  captains: {
    nodes: Array<Pick<User, 'firstName' | 'lastName'>>
  }
  criteria: {
    combinator: Group['criteria']['combinator']
    rules: {
      nodes: Array<Pick<GroupCriteriaRule, 'id' | 'predicate' | 'property' | 'values'>>
    }
  }
  emailContacts: Pick<Group['contacts'], 'totalCount'>
  smsContacts: Pick<Group['contacts'], 'totalCount'>
  phoneContacts: Pick<Group['contacts'], 'totalCount'>
  dataCompId?: string
} & SharedInertiaProps

export default function GroupCard({
  id,
  name,
  description,
  streetAddress,
  city,
  postcode,
  state,
  pointPeople,
  notifyAllAdmins,
  publicUrl,
  status,
  emailContacts,
  phoneContacts,
  smsContacts,
  supporters,
  captains,
  criteria,
  permissions,
  dataCompId,
}: GroupCardProps) {
  const { canEditGroup } = permissions

  return (
    <InfoCard dataCompId={dataCompId} data-testid={`group-card-${name}`}>
      <Slot name="heading">
        <h2 className="tw-text-gray-900 tw-font-semibold tw-text-2xl tw-w-fit hover:tw-text-primary-700">
          <a href={`/groups/${id}`} aria-describedby={`group-card-${id}-go-to-group`}>
            {name}
          </a>
        </h2>

        <p data-testid="group-description" className="tw-text-gray-900">
          {description}
        </p>

        <GroupAddress streetAddress={streetAddress} city={city} postcode={postcode} state={state} />
      </Slot>

      <Slot name="stats">
        <div className="tw-flex tw-flex-col tw-gap-1">
          <span className="tw-text-gray-blue-500 tw-font-semibold">{t('group_supporter_stats.title')}</span>
          <span data-testid="supporters-count">{supporters.totalCount}</span>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <div className="tw-flex tw-gap-1">
            <span className="tw-text-gray-blue-500 tw-font-semibold">{t('group_contacts_stats.title')}</span>
            <span className="tw-text-gray-blue-500 tw-font-semibold">({t('group_contacts_stats.description')})</span>
          </div>
          <div>
            <span data-testid="emails-count">
              {t('group_contacts_stats.emails', {
                count: emailContacts.totalCount,
              })}
            </span>
            ,&nbsp;
            <span data-testid="calls-count">
              {t('group_contacts_stats.calls', {
                count: phoneContacts.totalCount,
              })}
            </span>
            ,&nbsp;
            <span data-testid="sms-count">{t('group_contacts_stats.sms', { count: smsContacts.totalCount })}</span>
          </div>
        </div>
      </Slot>

      <Slot name="main">
        <div className="tw-flex tw-flex-col tw-gap-5">
          <GroupCriteria criteria={criteria} />

          <div className="tw-flex tw-flex-col tw-gap-3">
            <PointPeople
              groupName={name}
              notifyAllAdmins={Boolean(notifyAllAdmins)}
              pointPersonEmails={pointPeople.nodes.map((pp) => pp.email)}
            />

            <LeaderNames leaderType="captains" leaders={captains.nodes} />
          </div>
        </div>
      </Slot>

      <Slot name="actions">
        <Slot name="primary">
          <Link
            dataCompId={`${dataCompId}-groups-info-card_go-to-group`}
            size="sm"
            trailingIcon="arrow-circle-broken-right"
            ariaLabel={t('aria_labels.go_to_group', { name })}
            href={`/groups/${id}`}
            id={`group-card-${id}-go-to-group`}
            inertiaLink={true}
          >
            {t('buttons.go_to_group')}
          </Link>
        </Slot>
        <Slot name="secondary">
          {canEditGroup && (
            <>
              <Link
                dataCompId={`${dataCompId}-groups-info-card_edit-group`}
                size="sm"
                rank="link"
                leadingIcon="pencil-02"
                ariaLabel={t('aria_labels.edit_group', { name })}
                href={`/groups/${id}/edit`}
              >
                {t('buttons.edit_group')}
              </Link>
              <Link
                size="sm"
                rank="link"
                leadingIcon="pencil-02"
                ariaLabel={t('aria_labels.edit_group_criteria', { name })}
                href={`/groups/${id}/criteria/edit`}
              >
                {t('buttons.edit_group_criteria')}
              </Link>
              <Link
                dataCompId={`${dataCompId}-groups-info-card_manage-captains`}
                size="sm"
                rank="link"
                leadingIcon="users-01"
                ariaLabel={t('aria_labels.manage_captains', {
                  name,
                })}
                href={`/groups/${id}/captains`}
              >
                {t('buttons.manage_captains')}
              </Link>
            </>
          )}

          <Link
            size="sm"
            rank="link"
            leadingIcon="line-chart-up"
            ariaLabel={t('aria_labels.analytics', { name })}
            href={`/en/supporter_groups/${id}/analytics/supporters?v2=true`}
          >
            {t('buttons.analytics')}
          </Link>

          <Link
            size="sm"
            rank="link"
            leadingIcon="eye"
            ariaLabel={t('aria_labels.public_page', { name })}
            disabled={status === 'hidden' || !publicUrl}
            href={publicUrl ?? ''}
          >
            {t('buttons.public_page')}
          </Link>
        </Slot>
      </Slot>
    </InfoCard>
  )
}
