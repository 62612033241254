import React from 'react'
import { router } from '@inertiajs/react'

import CriteriaAdapter from '../adapters/CriteriaAdapter'

import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'

import { scopedTranslation } from '@utils/I18n'

import { useErrorsStore } from '@stores/errorsStore'

import { FormFullPageLayout, StepLayout } from '@layouts'

import Button from '@atoms/Button'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import * as FormContainer from '@molecules/FormContainer'
import BackLink from '@molecules/BackLink'

import type { AddDraftGroupCriteriaDocument, DraftGroup } from '@frontend/graphql/types.generated'

import GroupDetailsPreview from '../features/create-new-group/group-details/GroupDetailsPreview'
import CriteriaPreview from '../features/edit-group-criteria/CriteriaPreview'
import CriteriaEdit from '../features/edit-group-criteria/CriteriaEdit'
import { addDraftGroupCriteriaMutation } from '../features/create-new-group/add-supporters/mutations'

const tGroupCreate = scopedTranslation('group_create')
const tShared = scopedTranslation('shared')

function StepNumber() {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-full tw-text-base tw-font-semibold tw-w-8 tw-h-8">
      2
    </div>
  )
}

type AddSupportersProps = {
  draftGroup: Pick<
    DraftGroup,
    'id' | 'name' | 'description' | 'streetAddress' | 'city' | 'postcode' | 'state' | 'notifyAllAdmins' | 'pointPeople'
  >
}

export default function AddSupporters({ draftGroup }: AddSupportersProps) {
  const draftGroupId = draftGroup.id

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const { control, handleSubmit } = useFormWithErrors({
    mode: 'onTouched',
    defaultValues: {
      group: {
        criteria: {
          combinator: 'AND',
          rules: {},
        },
      },
    },
  })

  const { mutate } = useMutation<typeof AddDraftGroupCriteriaDocument>(addDraftGroupCriteriaMutation)

  const onSubmit = async (values: any) => {
    mutate(
      {
        id: draftGroupId,
        criteria: CriteriaAdapter.fromFrontend(values.group.criteria).toServer(),
      },
      {
        onSuccess: (response) => {
          if (response.addDraftGroupCriteria?.success) {
            router.get(`/draft_groups/${draftGroupId}/captains/new`)
          } else {
            setAppErrors({
              errorsPath: 'addDraftGroupCriteria',
              errors: response.addDraftGroupCriteria?.errors ?? [],
            })
          }
        },
        onError: (error) => {
          console.error('error', error)
        },
      }
    )
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <AppErrorsAlert errorsPath="addDraftGroupCriteria" />
      <div className="tw-flex tw-flex-col tw-gap-4">
        <FormContainer.Root>
          <FormContainer.FormCard dataCompId={'add_supporters_form'}>
            <FormContainer.FormCardHeader title={tGroupCreate('group_details_form.title')} />
            <FormContainer.Form>
              <FormContainer.FormHeader title={tGroupCreate('add_supporters_form.title')} icon={<StepNumber />} />
              <CriteriaEdit control={control} />
            </FormContainer.Form>
          </FormContainer.FormCard>
          <FormContainer.PreviewCard dataCompId={'add-supporters_preview'}>
            <FormContainer.PreviewCardHeader
              title={tShared('group_criteria.preview_title', {
                group_name: draftGroup.name,
              })}
            />
            <GroupDetailsPreview
              name={draftGroup.name}
              notifyAllAdmins={Boolean(draftGroup.notifyAllAdmins)}
              pointPeople={draftGroup.pointPeople.nodes?.map((pp) => pp?.fullName ?? '') ?? []}
              description={draftGroup.description}
              streetAddress={draftGroup.streetAddress ?? null}
              city={draftGroup.city ?? null}
              postcode={draftGroup.postcode ?? null}
              state={draftGroup.state ?? null}
            />
            <CriteriaPreview control={control} />
          </FormContainer.PreviewCard>
        </FormContainer.Root>

        <div className="tw-flex tw-justify-end">
          <BackLink to={`/draft_groups/${draftGroupId}/edit`} leadingIcon="arrow-narrow-left" rank="secondary">
            {tGroupCreate('add_supporters_form.back_button_text')}
          </BackLink>
          <Button type="submit" size="md" trailingIcon="arrow-circle-broken-right" onClick={handleSubmit(onSubmit)}>
            {`${tGroupCreate('buttons.next')}: ${tGroupCreate('form_steps.invite_captains')}`}
          </Button>
        </div>
      </div>
    </div>
  )
}

AddSupporters.layout = (page) => (
  <FormFullPageLayout>
    <StepLayout
      steps={[
        tGroupCreate('form_steps.group_details'),
        tGroupCreate('form_steps.add_supporters'),
        tGroupCreate('form_steps.invite_captains'),
      ]}
      currentStep={1}
    >
      {page}
    </StepLayout>
  </FormFullPageLayout>
)
