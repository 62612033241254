import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { useEffect } from 'react'
import type { DraftUser } from '@frontend/graphql/types.generated'

type State = {
  captains: DraftUser[]
}

type Actions = {
  removeCaptain: (captain: DraftUser, setError?: any) => void
  addCaptain: (captain: DraftUser, setError?: any) => void
  setState: (state: Partial<State>) => void
}

export type CaptainsStoreType = State & Actions

const useStore = create<CaptainsStoreType>((set) => ({
  captains: [],
  removeCaptain: (captain: DraftUser) => {
    set(({ captains }) => ({
      captains: captains.filter((c) => c.email !== captain.email),
    }))
  },
  addCaptain: (captain: DraftUser) => {
    set(({ captains }) => {
      const existingCaptain = captains.find((c) => c.email === captain.email)

      return {
        captains: existingCaptain ? captains : [...captains, captain],
      }
    })
  },
  setState: (state: Partial<State>) => {
    set(() => state)
  },
}))

export function useCaptainsStore(initialState?: Partial<State>) {
  const { captains, addCaptain, removeCaptain, setState } = useStore((state) => ({ ...state }), shallow)

  useEffect(() => {
    setState(initialState || {})
  }, [])

  return {
    captains,
    addCaptain,
    removeCaptain,
    setState,
  }
}
