import React from 'react'
import { useController, type UseFormRegister, useWatch } from 'react-hook-form'

import { scopedTranslation } from '@utils/I18n'

import { TextInput } from '@atoms'
import AsyncSelect from '@molecules/AsyncSelect'
import TagsSelect from '@molecules/TagsSelect'

import { type FieldProps } from './PropertyField'
import { type FilterFormValues } from './FilterDialog'

const t = scopedTranslation('components.filter_dialog')

export type ValueFieldProps = Omit<FieldProps, 'update'> & {
  register: UseFormRegister<FilterFormValues>
}

const ValueField = ({ register, control, index, filteringOptions }: ValueFieldProps) => {
  const filter = useWatch({ control, name: `rules.${index}` })
  const { property, predicate } = filter
  const fieldName = `rules.${index}.values` as const

  const { field } = useController({
    control,
    name: fieldName,
  })

  if (!property || !predicate) return null

  if (predicate === 'present' || predicate === 'blank' || predicate === 'true') {
    return <></>
  }

  const isPredicateRequiresArray = () => {
    const currentPredicate = filteringOptions[property].predicates.find(
      (filteringOptionsPredicate) => filteringOptionsPredicate.value === predicate
    )
    return currentPredicate?.array === true
  }

  if (isPredicateRequiresArray()) {
    if (property === 'tags') {
      return <TagsSelect field={field} displayName={t('labels.values')} hideLabel={true} />
    }

    // Stub the search endpoint by returning an empty array of existing postcodes
    const loadOptions = async () => {
      return []
    }
    return (
      <AsyncSelect
        field={field}
        loadOptions={loadOptions}
        displayName={t('labels.values')}
        hideLabel={true}
        createOptionPrefix=""
      ></AsyncSelect>
    )
  }

  return (
    <TextInput
      type="text"
      displayName={t('labels.search_value')}
      hideLabel={true}
      errors={[] as any}
      {...register(fieldName)}
    />
  )
}

export default ValueField
