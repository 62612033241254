import React from 'react'
import { router } from '@inertiajs/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { FormProvider } from 'react-hook-form'

import useFormWithErrors from '@hooks/useFormWithErrors'

import { useErrorsStore } from '@stores/errorsStore'

import type {
  AddDraftGroupUserDocument,
  CreateGroupDocument,
  DraftUser,
  GroupCriteriaRule,
  RemoveDraftGroupUserDocument,
} from '@frontend/graphql/types.generated'
import useMutation from '@hooks/useGQLMutation'
import CriteriaAdapter from '../adapters/CriteriaAdapter'

import { scopedTranslation } from '@utils/I18n'

import { Button } from '@atoms'

import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import * as FormContainer from '@molecules/FormContainer'
import BackLink from '@molecules/BackLink'

import { StepLayout, FormFullPageLayout } from '@layouts'

import { serverErrorsToFormErrors } from '../../../adapters/serverErrorsAdapter'
import { useCaptainsStore } from '../features/manage-leaders/hooks/useCaptainsStore'
import DisplayCaptains from '../features/manage-leaders/DisplayCaptains'
import AddCaptain, { validationSchema } from '../features/manage-leaders/AddCaptain'
import {
  addDraftGroupUserMutation,
  createGroupMutation,
  removeDraftGroupUserMutation,
} from '../features/create-new-group/add-leaders/mutations'
import GroupDetailsPreview from '../features/create-new-group/group-details/GroupDetailsPreview'
import CriteriaPreview from '../features/edit-group-criteria/CriteriaPreview'

const t = scopedTranslation('group_create.manage_captains_form.table')
const groupCreateT = scopedTranslation('group_create')

function StepNumber() {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-full tw-text-base tw-font-semibold tw-w-8 tw-h-8">
      3
    </div>
  )
}

type GroupCriteria = {
  combinator: string
  rules: Array<Pick<GroupCriteriaRule, 'property' | 'predicate' | 'values'>>
}

type FrontendCriteria = {
  combinator: string
  rules: Record<
    string,
    {
      enabled: boolean
      predicate: string
      values: Array<{ value: string; label: string }>
    }
  >
}

function CaptainsPreview({ captains }: { captains: DraftUser[] }) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-5 tw-pb-6">
      <h3 className="tw-text-lg tw-font-semibold">{groupCreateT('manage_captains_form.labels.captains_invited')}</h3>
      {captains.length > 0 && (
        <table className="table-auto tw-rounded tw-border tw-border-gray-100">
          <thead className="">
            <tr>
              <th className="tw-text-sm tw-bg-gray-100 tw-py-3 tw-px-6">{t('email')}</th>
            </tr>
          </thead>
          <tbody>
            {captains.map((captain) => (
              <tr key={captain.email}>
                <td className="tw-text-sm tw-py-3 tw-px-6 tw-rounded tw-border-t tw-border-t-gray-100">
                  {captain.email}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

type ManageCaptainsPropsType = {
  draftGroup: {
    id: string
    users: DraftUser[]
    criteria: GroupCriteria
    name: string
    notifyAllAdmins: boolean
    description: string
    streetAddress: string | null
    city: string | null
    postcode: string | null
    state: string | null
    pointPeople?: {
      nodes: Array<{
        id: number
        fullName: string
      }>
    }
  }
}
export default function AddLeaders({ draftGroup }: ManageCaptainsPropsType) {
  const groupId = draftGroup.id
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const { setErrors: setFormErrors, ...formMethods } = useFormWithErrors({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })
  const { captains, removeCaptain, addCaptain } = useCaptainsStore({
    captains: draftGroup?.users,
  })

  const { control: groupCriteriaControlForCriteriaPreview } = useFormWithErrors({
    defaultValues: {
      group: {
        criteria: CriteriaAdapter.fromServerDraftGroup(draftGroup.criteria).toFrontend(),
      },
    },
  })

  const { mutate: addDraftGroupUser } = useMutation<typeof AddDraftGroupUserDocument>(addDraftGroupUserMutation)
  const { mutate: removeDraftGroupUser } =
    useMutation<typeof RemoveDraftGroupUserDocument>(removeDraftGroupUserMutation)
  const { mutate: createGroupMutate } = useMutation<typeof CreateGroupDocument>(createGroupMutation)

  function addCaptainMutation(captain: DraftUser) {
    clearAppErrors('manageCaptains')
    addDraftGroupUser(
      { id: groupId, user: captain },
      {
        onSuccess: (response) => {
          if (!response.addDraftGroupUser) return

          if (response.addDraftGroupUser.success && response.addDraftGroupUser.user) {
            addCaptain(response.addDraftGroupUser.user)
            formMethods.reset()
          } else {
            setAppErrors({ errorsPath: 'manageCaptains', errors: response.addDraftGroupUser.errors })
            setFormErrors({
              errorsBasePath: 'group',
              errors: response.addDraftGroupUser?.errors,
              adapter: serverErrorsToFormErrors,
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'manageCaptains',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const removeCaptainMutation = (captain: DraftUser) => {
    clearAppErrors('manageCaptains')
    removeDraftGroupUser(
      { id: groupId, email: captain.email },
      {
        onSuccess: (response) => {
          if (!response.removeDraftGroupUser) return

          if (response.removeDraftGroupUser.success && response.removeDraftGroupUser.user) {
            removeCaptain(response.removeDraftGroupUser.user)
          } else {
            setAppErrors({ errorsPath: 'manageCaptains', errors: response.removeDraftGroupUser.errors })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'manageCaptains',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const createGroup = () => {
    clearAppErrors('manageCaptains')
    createGroupMutate(
      { id: groupId },
      {
        onSuccess: (response) => {
          if (!response.createGroup) return

          if (response.createGroup.success && response.createGroup.group) {
            router.get('/layout_redirects', { path: `/groups/${response.createGroup.group.id}` })
          } else {
            setAppErrors({ errorsPath: 'manageCaptains', errors: response.createGroup.errors })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'manageCaptains',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <AppErrorsAlert errorsPath="manageCaptains" />
      <FormContainer.Root>
        <FormContainer.FormCard dataCompId={'manage-captains_card'}>
          <FormContainer.FormCardHeader title={groupCreateT('group_details_form.title')} />
          <FormContainer.Form>
            <FormContainer.FormHeader
              title={groupCreateT('manage_captains_form.title')}
              description={groupCreateT('manage_captains_form.description')}
              icon={<StepNumber />}
            />
            <FormProvider {...formMethods}>
              <div className="tw-flex tw-flex-col tw-gap-7 tw-rounded">
                <AddCaptain addCaptain={addCaptainMutation} />
                <div className="tw-h-1 tw-bg-gray-200"></div>
                <div className="tw-flex tw-flex-col tw-gap-5">
                  <h3 className="tw-text-base tw-font-semibold">
                    {groupCreateT('manage_captains_form.labels.captains_invited')}
                  </h3>
                  <DisplayCaptains captains={captains} removeCaptain={removeCaptainMutation} />
                </div>
              </div>
            </FormProvider>
          </FormContainer.Form>
        </FormContainer.FormCard>

        <FormContainer.PreviewCard dataCompId={'manage-captains_preview'}>
          <FormContainer.PreviewCardHeader
            title={groupCreateT('group_details_form.preview_title', {
              group_name: draftGroup.name,
            })}
          />
          <GroupDetailsPreview
            name={draftGroup.name}
            notifyAllAdmins={draftGroup.notifyAllAdmins}
            pointPeople={draftGroup.pointPeople?.nodes?.map((pp) => pp.fullName) ?? []}
            description={draftGroup.description}
            streetAddress={draftGroup.streetAddress ?? null}
            city={draftGroup.city ?? null}
            postcode={draftGroup.postcode ?? null}
            state={draftGroup.state ?? null}
          />
          <CriteriaPreview control={groupCriteriaControlForCriteriaPreview} />
          <CaptainsPreview captains={captains} />
        </FormContainer.PreviewCard>
      </FormContainer.Root>

      <div className="tw-flex tw-justify-end tw-w-full tw-gap-2">
        <BackLink to={`/draft_groups/${draftGroup.id}`} rank="secondary">
          {groupCreateT('manage_captains_form.back_button_text')}
        </BackLink>
        <Button type="submit" size="md" trailingIcon="arrow-circle-broken-right" onClick={createGroup}>
          {groupCreateT('buttons.create_group')}
        </Button>
      </div>
    </div>
  )
}

AddLeaders.layout = (page) => (
  <FormFullPageLayout>
    <StepLayout
      steps={[
        groupCreateT('form_steps.group_details'),
        groupCreateT('form_steps.add_supporters'),
        groupCreateT('form_steps.invite_captains'),
      ]}
      currentStep={2}
    >
      {page}
    </StepLayout>
  </FormFullPageLayout>
)
