import React from 'react'
import { type Control, useController } from 'react-hook-form'

import ToggleableField from '@molecules/ToggleableField'
import TagsSelect from '@molecules/TagsSelect'
import AsyncSelect from '@molecules/AsyncSelect'
import { scopedTranslation } from '@utils/I18n'

const tShared = scopedTranslation('shared')

type CriteriaRule = Record<
  string,
  {
    values: Array<{
      value: string
      label: string
    }>
    enabled: boolean
    predicate: string
  }
>

export type Criteria = {
  criteria: {
    combinator: string
    rules: CriteriaRule
  }
}

type CriteriaRuleFieldProps = {
  name: string
  predicate: string
  label: string
  tooltip: string
  control: Control<Record<'group', Criteria>>
}
function TagsField({ name, predicate, label, tooltip, control }: CriteriaRuleFieldProps) {
  const fieldName: `group.criteria.rules.${string}` = `group.criteria.rules.${name}`

  useController({
    name: fieldName,
    defaultValue: {
      values: [],
      enabled: false,
      predicate,
    },
    control,
  })

  const { field: enabledField } = useController({
    name: `${fieldName}.enabled`,
    control,
  })

  const { field: tagsField } = useController({
    name: `${fieldName}.values`,
    control,
  })

  return (
    <ToggleableField
      label={label}
      name={fieldName}
      tooltip={tooltip}
      isEnabled={enabledField.value}
      setEnabled={(value: boolean) => {
        enabledField.onChange(value)
      }}
    >
      <TagsSelect field={tagsField} displayName={name} hideLabel={true} />
    </ToggleableField>
  )
}

// NOTE: This heavily duplicates the TagsField, with some minor customisations
function PostcodesField({ name, predicate, label, tooltip, control }: CriteriaRuleFieldProps) {
  const fieldName: `group.criteria.rules.${string}` = `group.criteria.rules.${name}`

  // Stub the search endpoint by returning an empty array of existing postcodes
  const loadOptions = async () => {
    return []
  }

  useController({
    name: fieldName,
    defaultValue: {
      values: [],
      enabled: false,
      predicate,
    },
    control,
  })

  const { field: enabledField } = useController({
    name: `${fieldName}.enabled`,
    control,
  })

  const { field: postcodesField } = useController({
    name: `${fieldName}.values`,
    control,
  })

  const displayName = tShared('resource_names.postcodes')

  return (
    <ToggleableField
      label={label}
      name={fieldName}
      tooltip={tooltip}
      isEnabled={enabledField.value}
      setEnabled={(value: boolean) => {
        enabledField.onChange(value)
      }}
    >
      <AsyncSelect
        hideLabel={true}
        displayName={name}
        field={postcodesField}
        loadOptions={loadOptions}
        noOptionsMessage={tShared('placeholders.placeholder_add', { name: displayName.toLowerCase() })}
        createOptionPrefix={tShared('buttons.add')}
      />
    </ToggleableField>
  )
}

type CriteriaEditProps = {
  control: Control<Record<'group', Criteria>>
}

export default function CriteriaEdit({ control }: CriteriaEditProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <TagsField
        name="tags"
        predicate="has_any"
        label={tShared('group_criteria.labels.tagged_with')}
        tooltip={tShared('group_criteria.tooltips.tagged_with')}
        control={control}
      />
      <TagsField
        name="exclusionTags"
        predicate="not_any"
        label={tShared('group_criteria.labels.not_tagged_with')}
        tooltip={tShared('group_criteria.tooltips.not_tagged_with')}
        control={control}
      />
      <PostcodesField
        name="zip"
        predicate="in"
        label={tShared('group_criteria.labels.postcodes')}
        tooltip={tShared('group_criteria.tooltips.postcodes')}
        control={control}
      />{' '}
    </div>
  )
}
