import React, { createContext, useContext } from 'react'
import { createStore } from 'zustand'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { type SharedInertiaProps } from '../../../pages/GroupShow'
import {
  createFilterSlice,
  type StoreState as FilterStoreState,
  type StoreActions as FilterStoreActions,
} from './filterStoreSlice'
import {
  createSortSlice,
  type StoreState as SortStoreState,
  type StoreActions as SortStoreActions,
} from './sortStoreSlice'
import {
  createNameSearchSlice,
  type StoreState as NameSearchStoreState,
  type StoreActions as NameSearchStoreActions,
} from './nameSearchStoreSlice'

export type StoreState = {
  setLoading: (loading: boolean) => void
} & FilterStoreState &
  SortStoreState &
  NameSearchStoreState

export type StoreActions = FilterStoreActions & SortStoreActions & NameSearchStoreActions

type SupporterFilteringStore = ReturnType<typeof createSupporterFilteringStore>

const DEFAULT_PROPS = {
  setLoading: () => {},
}

export const createSupporterFilteringStore = (
  initProps: Partial<StoreState>,
  permissions: SharedInertiaProps['permissions']
) => {
  return createStore<StoreState & StoreActions>()((set, get, store) => ({
    ...createSortSlice(set, get, store),
    ...createFilterSlice(permissions, set, get, store),
    ...createNameSearchSlice(set, get, store),
    ...DEFAULT_PROPS,
    ...initProps,
  }))
}

const SupporterFilteringContext = createContext<SupporterFilteringStore | null>(null)

export function SupporterFilteringProvider({
  children,
  initProps,
  permissions,
}: {
  children: React.ReactNode
  initProps: Partial<StoreState>
} & SharedInertiaProps) {
  const store = createSupporterFilteringStore(initProps, permissions)
  return <SupporterFilteringContext.Provider value={store}>{children}</SupporterFilteringContext.Provider>
}

export default function useSupporterFilteringStore<U>(selector: (state: StoreState & StoreActions) => U): U {
  const store = useContext(SupporterFilteringContext)
  if (!store) {
    throw new Error('Missing SupporterDetailsProvider in the tree')
  }
  return useStoreWithEqualityFn<SupporterFilteringStore, U>(store, selector, shallow)
}
