import gql from 'graphql-tag'

export const addSupporterToGroupMutation = gql`
  mutation addSupporterToGroup(
    $groupId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $mobile: String
    $address1: String
    $address2: String
    $city: String
    $zip: String
    $state: String
    $countryCode: String
    $tags: [String!]
    $engagementLevel: String
  ) {
    addSupporterToGroup(
      input: {
        groupId: $groupId
        firstName: $firstName
        lastName: $lastName
        email: $email
        mobile: $mobile
        address1: $address1
        address2: $address2
        city: $city
        zip: $zip
        state: $state
        countryCode: $countryCode
        tags: $tags
        engagementLevelId: $engagementLevel
      }
    ) {
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
