import { Button } from '@atoms'
import React, { useState } from 'react'
import { scopedTranslation } from '@utils/I18n'
import useMutation from '@hooks/useGQLMutation'
import { exportSupportersCsv } from '../features/export-supporters/mutations'
import { ExportSupportersCsvDocument } from '../features/export-supporters/mutations.generated'

const tSupportersExport = scopedTranslation('supporters_export')

export default function SupportersExport({ userEmail }: { userEmail: string }) {
  const [isExporting, setIsExporting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const { mutate } = useMutation<typeof ExportSupportersCsvDocument>(exportSupportersCsv)

  const exportingCsv = () => {
    setIsExporting(true)
    setIsSuccess(false)
    setIsError(false)
    mutate(
      {},
      {
        onSuccess: (response) => {
          if (!response.exportSupportersCsv) return

          if (response.exportSupportersCsv.success) {
            setIsSuccess(true)
          } else {
            setIsError(true)
          }
        },
        onError: () => {
          setIsError(true)
        },
        onSettled: () => {
          setIsExporting(false)
        },
      }
    )
  }

  return (
    <div className="tw-flex tw-p-20 tw-justify-center">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-font-medium tw-items-center">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-w-fit">
          <Button onClick={exportingCsv} disabled={isExporting} size="md">
            {isExporting
              ? tSupportersExport('supporters_export_csv_button_loading')
              : tSupportersExport('supporters_export_csv_button')}
          </Button>
        </div>
        {isSuccess && (
          // render the modal or success message
          <p>{tSupportersExport('supporters_export_success', { email: userEmail })}</p>
        )}
        {isError &&
          // render the modal or error message
          tSupportersExport('supporters_export_fail', { support_email: 'info@supporterbase.com' })}
        {isSuccess || isError || <p>{tSupportersExport('email_send_tip', { email: userEmail })}</p>}
      </div>
    </div>
  )
}
