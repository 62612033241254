import { type Control, useController } from 'react-hook-form'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import React from 'react'

import MenuBar from './MenuBar'

type RichTextProps = {
  displayName: string
  name: string
  control: Control<any>
  required?: boolean
  value?: any
  hint?: string
}

export default function RichText({ displayName, hint, name, required, control }: RichTextProps) {
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  })

  // This edit uses styles overrides that are defined in application.tailwind.css
  // The styles apply to anything under a div with the class .wysiwyg
  const editor = useEditor({
    editorProps: {
      attributes: {
        'aria-labelledby': `${name}-description`,
        'aria-describedby': `${name}-hint`,
        role: 'textbox',
        class: 'focus:tw-outline-none wysiwyg tw-min-h-[12ch]',
      },
    },
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: field.value || '',
    onUpdate: ({ editor }) => {
      field.onChange(editor.getHTML())
    },
    onBlur: () => {
      field.onBlur()
    },
  })

  return (
    <>
      <label className={'tw-text-gray-700'} id={`${name}-description`} htmlFor={name}>
        {displayName} {required && '(required)'}
      </label>
      <div className="tw-px-3 tw-py-2 tw-border tw-rounded tw-border-gray-300 focus-within:tw-border-primary-400 tw-bg-white">
        {editor && <MenuBar editor={editor} />}
        <EditorContent editor={editor} />
      </div>
      {hint && (
        <p id={`${name}-hint`} className="tw-text-gray-500 tw-text-sm">
          {hint}
        </p>
      )}
      {/* role="status" sets aria-live="polite" and aria-atomic="true" */}
      <div role="status" aria-relevant="all">
        {errors && (
          <p id={`${field.name}.error`} className="tw-text-red-500 tw-font-light tw-text-sm tw-pt-1">
            {errors.message}
          </p>
        )}
      </div>
    </>
  )
}
